
import { Component, Vue } from 'vue-property-decorator'
import AMapLoader from '@amap/amap-jsapi-loader'
import { mapKey } from '@/utils/config'
import { Info, TableInfo, MapData } from '@/types/location'
import Echarts from 'echarts'
import { drawPolygon } from '@/utils/formatData'

@Component({
  name: 'LocationDetail',
  filters: {
    deviceStatusFilter (value: string) {
      return value === '1' ? '在线' : value === '2' ? '离线' : ''
    }
  }
})
export default class LocationDetail extends Vue {
  private drawer = false
  private isShowDialog = false
  private drawerText = ''
  private radio = 'map'
  private info: Info = {
    machineId: '',
    projectId: ''
  }

  private deviceRules = {
    machineName: [
      { required: true, message: '请输入名称，如：洒水车—苏AB1234', trigger: ['blur', 'change'] }
    ]
  }

  private tableData = {
    loading: false,
    tr: [
      {
        label: '车牌号',
        prop: 'machineName',
        minWidth: '100',
        showOverflowTooltip: true
      },
      {
        label: '设备名称',
        prop: 'deviceTypeName',
        minWidth: '100',
        showOverflowTooltip: true
      },
      {
        label: '设备串号',
        prop: 'deviceNumber',
        minWidth: '100',
        showOverflowTooltip: true
      },
      {
        label: '状态',
        prop: 'deviceStatus',
        minWidth: '80',
        showOverflowTooltip: true
      },
      {
        label: '今日运行',
        prop: 'workingHours',
        minWidth: '120',
        showOverflowTooltip: true
      },
      {
        label: '速度(ｋｍ/ｈ)',
        prop: 'speed',
        minWidth: '80',
        showOverflowTooltip: true
      },
      {
        label: '时间',
        prop: 'collectTime',
        minWidth: '100',
        showOverflowTooltip: true
      },
      {
        label: '位置',
        prop: 'location',
        minWidth: '130',
        showOverflowTooltip: true
      }
    ],
    data: []
  }

  private onlineAmount = ''
  private onlineImg = require('@/assets/icon/location/online.svg')
  private onlineImgCursor = require('@/assets/icon/location/online2.svg')
  private offImg = require('@/assets/icon/location/off.svg')
  private offImgCursor = require('@/assets/icon/location/off2.svg')
  private offLineAmount = ''
  private dialogTitle = '添加'
  private drawerData = {
    machineName: '',
    deviceTypeName: '',
    deviceNumber: '',
    deviceStatus: '',
    speed: '',
    collectTime: ''
  }

  private map: AMap.Map | any = null
  private machineList = []

  page = 1
  size = 10
  total = 0

  beforeRouteEnter (to: any, from: any, next: any) {
    next((vm: any) => {
    // 通过 `vm` 访问组件实例
      const radio: string = from.query.radio as string
      if (radio) {
        if (radio === 'map') {
          vm.getProjectLocation()
          next()
        } else {
          vm.getDeviceList()
          vm.getListData()
          next()
        }
        vm.radio = radio
      } else {
        vm.getProjectLocation()
        next()
      }
    })
  }

  // 页面离开
  destroyed () {
    if (this.map) {
      this.map.off('click', () => {
        this.drawerClose()
      })
      this.map.destroy()
    }
  }

  created () {
    this.info.projectId = this.$route.query.projectId
    if (this.$route.query.radio) {
      this.radio = this.$route.query.radio as string
      this.getListData()
    }
  }

  // 获取table列表接口
  getListData () {
    this.tableData.loading = true
    this.$axios.get(this.$apis.location.locationMachineListPage, {
      ...this.info,
      page: this.page,
      size: this.size
    }).then(res => {
      this.total = res.total || 0
      this.tableData.data = []
      this.disposeTableData(res.list)
      this.getMachineList()
    }).finally(() => {
      this.tableData.loading = false
    })
  }

  // 获取机械设备列表
  getMachineList () {
    this.$axios.get(this.$apis.location.selectAllWorkerList, {
      projectId: this.$route.query.projectId
    }).then((res) => {
      this.machineList = res.devices || []
    })
  }

  // 获取项目电子围栏
  getProjectLocation () {
    this.$axios.get(this.$apis.project.selectProjectAreaById, {
      projectId: this.info.projectId
    }).then((res) => {
      this.loadMap(res.projectInfo, res.projectLocation)
    })
  }

  // 获取地图设备数据
  getMapData () {
    this.$axios.get(this.$apis.location.selectLocationAndMachineList, {
      isPage: '0',
      projectId: this.$route.query.projectId
    }).then(res => {
      this.onlineAmount = res.onlineAmount || 0
      this.offLineAmount = res.offLineAmount || 0
      this.drawEchart()
      // 将设备marker添加到地图上
      res.devices.map((item: MapData) => {
        if (item.deviceNumber) {
          const lnglat = [Number(item.longitude || 0), Number(item.latitude || 0)]
          const icon = item.deviceStatus === '1' ? this.onlineImg : this.offImg
          const marker: any = new AMap.Marker({
            icon: new AMap.Icon({
            // 图标的取图地址
              image: icon
            }),
            offset: new AMap.Pixel(-18, -48),
            position: new AMap.LngLat(lnglat[0], lnglat[1]),
            label: {
              direction: 'top',
              content: '<div class="marker-label-content">' + item.machineName + '</div><div class="marker-label-sharp"></div>'
            }
          })
          marker.detail = item
          marker.on('click', (e: any) => {
          // 判断点击的是否是当前抽屉的设备
            if (this.drawerData.deviceNumber !== e.target.detail.deviceNumber) {
              this.drawerClose()
              this.drawer = true
              this.drawerData = e.target.detail
              const iconClick = item.deviceStatus === '1' ? this.onlineImgCursor : this.offImgCursor
              e.target.setOffset(new AMap.Pixel(-22, -64))
              e.target.setIcon(new AMap.Icon({
              // 图标的取图地址
                image: iconClick
              }))
              marker.detail = item
            }
          })
          this.map.add(marker)
        }
      })
    })
  }

  // 处理table列表数据
  disposeTableData (list: []) {
    // 添加位置字段，通过经纬度获取位置
    list.map((item: TableInfo) => {
      const obj = item
      obj.location = ''
      if (item.longitude && item.latitude) {
        AMap.plugin('AMap.Geocoder', () => {
          const geocoder = new AMap.Geocoder()
          geocoder.getAddress([Number(item.longitude), Number(item.latitude)], (status, result: any) => {
            if (result.regeocode) {
              obj.location = result.regeocode.formattedAddress
            }
          })
        })
      }
      (this.tableData.data as Array<TableInfo>).push(obj)
    })
  }

  // 渲染地图
  loadMap (projectInfo: {longitude: number; latitude: number}, list: Array<{ longitude: number; latitude: number; fenceCode: string }>) {
    AMapLoader.load({
      key: mapKey,
      version: '2.0',
      plugins: []
    }).then(() => {
      const center: [number, number] = [Number(projectInfo.longitude), Number(projectInfo.latitude)]
      const map = new AMap.Map('map', {
        center: center,
        zoom: 11
      })
      this.map = map
      this.map.on('click', this.drawerClose)
      // 画电子围栏
      drawPolygon(this.map, list, null, this.drawerClose)
      this.getMapData()
    })
  }

  // 抽屉关闭
  drawerClose () {
    if (this.drawer) {
      this.drawer = false
      // 将图标替换
      for (let i = 0; i < this.map.getAllOverlays('marker').length; i++) {
        if (this.drawerData.deviceNumber === this.map.getAllOverlays('marker')[i].detail.deviceNumber) {
          const icon = this.map.getAllOverlays('marker')[i].detail.deviceStatus === '1'
            ? this.onlineImg
            : this.offImg
          this.map.getAllOverlays('marker')[i].setOffset(new AMap.Pixel(-18, -48))
          this.map.getAllOverlays('marker')[i].setIcon(icon)
        }
      }

      // 清空抽屉
      this.drawerData = {
        machineName: '',
        deviceTypeName: '',
        deviceNumber: '',
        deviceStatus: '',
        speed: '',
        collectTime: ''
      }
    }
  }

  // 环形图
  drawEchart () {
    const pieEchart: any = Echarts.init(this.$refs.echarts as any)
    const option = {
      title: {
        show: false
      },
      color: ['#1AD1B0', 'rgb(210, 210, 210)'],
      legend: {
        icon: 'circle',
        itemHeight: 6,
        show: true,
        itemGap: 15,
        right: 20,
        top: '38%',
        orient: 'vertical',
        textStyle: {
          fontSize: 14 // 字体大小
        },
        formatter: (item: string) => {
          return item === '在线' ? `${item}  ${this.onlineAmount}` : `${item}  ${this.offLineAmount}`
        }
      },

      series: [{
        name: 'Line 1',
        type: 'pie',
        clockWise: true,
        radius: ['40%', '70%'],
        center: ['35%', '50%'],
        itemStyle: {
          normal: {
            label: {
              show: false
            },
            labelLine: {
              show: false
            }
          }
        },
        hoverAnimation: false,
        data: [{
          name: '在线',
          value: this.onlineAmount
        }, {
          name: '离线',
          value: this.offLineAmount
        }]
      }]
    }
    pieEchart.setOption(option)
  }

  // 地图列表切换
  radioChange () {
    if (this.radio === 'map') {
      if (this.map) {
        this.getMapData()
      } else {
        this.getProjectLocation()
      }
    } else {
      this.getListData()
      this.drawerClose()
      this.map.remove(this.map.getAllOverlays('marker')) // 清除所有karker
    }
  }

  onSearch () {
    this.page = 1
    this.getListData()
  }

  onPlayback (id: string) {
    this.$router.push({
      name: 'detailPlayback',
      params: { id },
      query: { projectId: this.info.projectId, radio: this.radio }
    })
  }
}
